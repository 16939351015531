import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 90%;
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
`;

export default Container;