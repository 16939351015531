import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Container from './library/container'

type LogoProps = {
  description?: string
}

const Logo = ({ description }: LogoProps) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "acorp-icon.png" }) {
        childImageSharp {
          fixed(height: 72) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.placeholderImage.childImageSharp.fixed}
      alt={description}
    />
  )
}

const LogoContainer = styled.div`
  height: 4rem;
`

const StyledHeader = styled.header`
  // background: rgba(0,0,0,.8);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-align: row;
  padding: 2rem 1rem;
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Container>
      <LogoContainer>
        <Logo description={siteTitle} />
      </LogoContainer>
    </Container>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
