import React from 'react'
import styled from 'styled-components'

const FooterText = styled.p`
    text-align: center;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #606060
`

export default function Footer() {
  return (
    <footer>
      <FooterText>
        © {new Date().getFullYear()} Acorp Inc. - All Rights Reserved
      </FooterText>
    </footer>
  )
}
